'use client';

import React, {
  createContext, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Grid,
} from '@mui/material';
import { usePathname } from 'next/navigation';
import { colorVariables } from '@/constants';
import { getCategories, getLocationSearch } from '@/lib/api/functions';
import { Category } from '@/lib/types/interfaces/Category';
import { useQuery } from '@/hooks/useQuery';
import { City } from '@/lib/types/interfaces/City';
import SearchHeader from '@/page-components/Search/SearchHeader';
import Footer from '@/components/Footer';

interface SearchContext {
  currentCategory?: Category | null;
  categories?: Category[];
  subCategories?: Category[];
  location?: City | null;
  term?: string | null
  setCurrentCategory: (category: Category | null) => void
}

export const ServicesLayoutContext = createContext<SearchContext>({
  location: null,
  categories: [],
  subCategories: [],
  term: null,
  currentCategory: null,
  setCurrentCategory: () => {},
});

const ServicesLayout = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const { queryParams } = useQuery();
  const path = usePathname();
  const pathItems = path.split('/');

  const [term, setTerm] = useState<string | null>(queryParams.get('term')?.toString() || '');
  const [location, setLocation] = useState<City | null>(null);
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);

  const fetchData = useCallback(async () => {
    try {
      const response = await getCategories();
      setCategories(response.data);

      const category = response.data.find(item => pathItems.includes(item.slug)) || null;
      setCurrentCategory(category);

      const subCategoriesResponse = category?.id && await getCategories([category.id]);
      subCategoriesResponse && setSubCategories(subCategoriesResponse.data);
      const currentSubcategory = subCategoriesResponse && subCategoriesResponse.data.find(item => pathItems.includes(item.slug));

      const hasTerm = queryParams.get('term')?.toString();

      if (hasTerm) {
        return;
      }

      if (category && !currentSubcategory) {
        setTerm(category.name);
      }
      if (currentSubcategory) {
        setTerm(currentSubcategory.name);
      }
    } catch (err) {
      console.error(err);
    }
  }, [path]);

  const fetchLocation = useCallback(async () => {
    let state;
    let city;

    const stateIndex = pathItems.findIndex(item => item.length === 2);

    if (stateIndex !== -1) {
      state = pathItems[stateIndex];
      city = pathItems[stateIndex + 1];
    }

    if (city && state) {
      try {
        const response = await getLocationSearch(city);

        setLocation(
          response?.data
            .find((loc) => loc.state === state && loc.name === city.replaceAll('_', ' '))
          || null,
        );
      } catch (error) {
        console.error(error);
      }
    } else {
      setLocation(null);
    }
  }, [path]);

  useEffect(() => {
    fetchData();
    fetchLocation();
  }, [path]);

  return (
    <ServicesLayoutContext.Provider value={useMemo(() => ({
      term,
      location,
      categories,
      subCategories,
      currentCategory,
      setCurrentCategory,
    }), [currentCategory, subCategories, term, location])}
    >
      <SearchHeader />
      <main>
        <Grid
          container
          spacing={0}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            sx={{
              borderLeft: `1px solid ${colorVariables.separatorColor}`,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {children}
          </Grid>
        </Grid>
      </main>
      <Footer />
    </ServicesLayoutContext.Provider>
  );
};
export default ServicesLayout;
