'use client';

import {
  Box,
  FormGroup,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import React, {
  useEffect, useState,
} from 'react';

import { useRouter } from 'next/navigation';
import {
  breakCrumbsHeight, colorVariables, miles, searchResultsHeight, servicesListHeight,
} from '@/constants';
import { useQuery } from '@/hooks/useQuery';

interface Props {
  children?: React.ReactNode;
}

const Filters = ({ children }: Props) => {
  const route = useRouter();
  const { queryParams } = useQuery();
  const [selectedMiles, setSelectedMiles] = useState(miles.find(mile => mile === queryParams.get('radius')) || miles[0]);
  const [isSelectedMilesVisible, setIsSelectedMilesVisible] = useState(false);

  useEffect(() => {
    if (queryParams.has('loc')) {
      setIsSelectedMilesVisible(true);
    } else {
      setIsSelectedMilesVisible(false);
    }
  }, [queryParams.get('loc')]);

  useEffect(() => {
    if (queryParams.has('radius')) {
      setSelectedMiles(queryParams.get('radius') || miles[0]);
    }
  }, [queryParams.get('radius')]);

  const handleMilesChange = async (e: SelectChangeEvent) => {
    const newRadius = e.target.value;
    setSelectedMiles(newRadius);

    const newSearchParams = new URLSearchParams(queryParams.toString());
    newSearchParams.set('radius', newRadius);
    newSearchParams.delete('page');

    route.push(`/hire?${newSearchParams.toString()}`);
  };

  return (
    <Box
      sx={{
        padding: '16px 0 16px 16px',
        borderLeft: `1px solid ${colorVariables.separatorColor}`,
        maxHeight: searchResultsHeight + servicesListHeight + breakCrumbsHeight,
        overflow: 'auto',
      }}
    >
      <Box
        display='flex'
        justifyContent='space-between'
      >
        <Typography
          variant='body1'
          fontWeight={700}
        >
          Filters
        </Typography>
        {children}
      </Box>

      {isSelectedMilesVisible && (
        <Box
          display='flex'
          alignItems='center'
          mr={1}
          mt={1}
          sx={{ gap: '12px' }}
        >
          <Typography
            fontSize={14}
            sx={{ textWrap: 'nowrap' }}
          >
            Results within
          </Typography>
          <Select
            labelId='miles-label'
            id='miles'
            value={selectedMiles}
            onChange={handleMilesChange}
            sx={{
              fontSize: 14,
              '.MuiSelect-select': {
                padding: '10px 32px 10px 14px',
              },
            }}
          >
            {miles.map((mile) => (
              <MenuItem
                key={mile}
                value={mile}
                sx={{
                  fontSize: 14,
                }}
              >
                {`${mile} miles`}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}

      <FormGroup />
      <Box />
    </Box>
  );
};

export default Filters;
