'use client';

import {
  Box,
  FormGroup,
  Typography,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, {
  useContext,
  useEffect, useState,
} from 'react';

import { usePathname, useRouter } from 'next/navigation';
import {
  breakCrumbsHeight, colorVariables, miles, searchResultsHeight, servicesListHeight,
} from '@/constants';
import { useQuery } from '@/hooks/useQuery';
import apiClient from '@/apiClient';
import { DTO } from '@/lib/types/interfaces/DTO';
import { City } from '@/lib/types/interfaces/City';
import { ServicesLayoutContext } from '../ServicesLayout/ServicesLayout';

interface Props {
  children?: React.ReactNode;
}

const Filters = ({ children }: Props) => {
  const route = useRouter();
  const path = usePathname();
  const { queryParams } = useQuery();
  const { term } = useContext(ServicesLayoutContext);
  const [selectedMiles, setSelectedMiles] = useState('25');
  const [isSelectedMilesVisible, setIsSelectedMilesVisible] = useState(false);
  const [locationId, setLocationId] = useState<number | null>(null);
  const pathItems = path.split('/');

  const getCities = async (query: string) => apiClient.get <DTO<City[]>>(`/geo/cities?${query}`, { cache: 'no-cache' });

  const getLocationId = async () => {
    const stateIndex = pathItems.findIndex(item => item.length === 2);
    const state = stateIndex !== -1 ? pathItems[stateIndex] : '';
    const city = stateIndex !== -1 ? pathItems[stateIndex + 1] : '';

    if (state && city) {
      const locationQuery = `state=${state}&city=${city}`;

      try {
        const { data } = await getCities(locationQuery);
        setLocationId(data?.[0]?.id);
      } catch (error) {
        console.error(error);
      }
    } else {
      setLocationId(null);
    }
  };

  useEffect(() => {
    getLocationId();
  }, [path]);

  useEffect(() => {
    const stateIndex = pathItems.findIndex(item => item.length === 2);
    const state = stateIndex !== -1 ? pathItems[stateIndex] : '';
    const city = stateIndex !== -1 ? pathItems[stateIndex + 1] : '';

    if (state?.length === 2 && city?.length) {
      setIsSelectedMilesVisible(true);
    } else {
      setIsSelectedMilesVisible(false);
    }
  }, [path]);

  const handleMilesChange = async (e: SelectChangeEvent) => {
    const newRadius = e.target.value;
    setSelectedMiles(newRadius);

    const newSearchParams = new URLSearchParams(queryParams.toString());
    newSearchParams.set('radius', newRadius);
    newSearchParams.delete('page');

    if (term) {
      newSearchParams.set('term', term);
    }

    route.push(`/hire?loc=${locationId}&${newSearchParams.toString()}`);
  };

  return (
    <Box
      sx={{
        padding: '16px 0 16px 16px',
        borderLeft: `1px solid ${colorVariables.separatorColor}`,
        maxHeight: searchResultsHeight + servicesListHeight + breakCrumbsHeight,
        overflow: 'auto',
      }}
    >
      <Box
        display='flex'
        justifyContent='space-between'
      >
        <Typography
          variant='body1'
          fontWeight={700}
        >
          Filters
        </Typography>
        {children}
      </Box>
      {isSelectedMilesVisible && (
        <Box
          display='flex'
          alignItems='center'
          mr={1}
          mt={1}
          sx={{ gap: '12px' }}
        >
          <Typography
            fontSize={14}
            sx={{ textWrap: 'nowrap' }}
          >
            Results within
          </Typography>
          <Select
            labelId='miles-label'
            id='miles'
            value={selectedMiles}
            onChange={handleMilesChange}
            sx={{
              minWidth: 100,
              fontSize: 14,
              '.MuiSelect-select': {
                padding: '10px 32px 10px 14px',
              },
            }}
          >
            {miles.map((mile) => (
              <MenuItem
                key={mile}
                value={mile}
                sx={{
                  fontSize: 14,
                }}
              >
                {`${mile} miles`}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}
      <FormGroup />
      <Box />
    </Box>
  );
};

export default Filters;
