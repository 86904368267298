'use client';

import Link from 'next/link';
import { Button, Box, useMediaQuery } from '@mui/material';

import Logo from '@/components/Logo';
import theme from '@/theme';
import SearchForm from '@/components/SearchForm';
import SearchHeaderControls from '@/page-components/Search/SearchHeader/SearchHeaderControls';
import { headerControlsHeight } from '@/constants';

const SearchHeader = () => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <header className='header-container'>
      {isDesktop && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Link
              href='/'
              aria-label='Get back to main page'
              style={{
                marginRight: '35px',
              }}
            >
              <Logo />
            </Link>
          </Box>
          <Box
            display='block'
            marginRight='auto'
          >
            <SearchForm />
          </Box>
        </>
      )}
      {!isDesktop && (
        <Box width='100%'>
          <SearchHeaderControls />
        </Box>
      )}
      {isDesktop && (
        <Box display='block'>
          <Link
            href='/add-listing'
            aria-label='Go to listing'
            legacyBehavior
            passHref
          >
            <Button
              href='/add-listing'
              variant='outlined'
              sx={{
                height: headerControlsHeight,
                width: 197,
                display: 'flex',
              }}
            >
              Add Your Business
            </Button>
          </Link>
        </Box>
      )}
    </header>
  );
};

export default SearchHeader;
