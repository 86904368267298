import { Box, Button, Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { usePathname, useRouter } from 'next/navigation';
import HireFilters from '@/page-components/Search/Filters';
import DrawerProps from '@/lib/types/interfaces/Drawer';
import Filters from '@/page-components/Services/Filters';

const FiltersDrawer = ({ open, toggleDrawer }: DrawerProps) => {
  const router = useRouter();
  const path = usePathname();
  const isHire = path.startsWith('/hire');

  const handleCloseDrawer = () => {
    toggleDrawer(false);
  };

  const handleClearFilters = () => {
    if (isHire) {
      router.push('/hire');
    } else {
      router.push('/services');
    }
    handleCloseDrawer();
  };

  return (
    <Drawer
      open={open}
      anchor='bottom'
      sx={{
        '& .MuiDrawer-paper': {
          height: '100%',
        },
      }}
    >
      {path.startsWith('/services') ? (
        <Filters>
          <CloseIcon
            sx={{ marginRight: 2 }}
            onClick={handleCloseDrawer}
          />
        </Filters>
      ) : (
        <HireFilters>
          <CloseIcon
            sx={{ marginRight: 2 }}
            onClick={handleCloseDrawer}
          />
        </HireFilters>
      )}
      <Box
        paddingX={2}
        paddingY={2}
        display='flex'
        flexDirection='column'
        marginTop='auto'
        gap={2}
      >
        <Button
          variant='contained'
          color='success'
          sx={{
            minHeight: 56,
          }}
          onClick={handleCloseDrawer}
        >
          Apply
        </Button>
        <Button
          variant='outlined'
          color='success'
          fullWidth
          sx={{
            minHeight: 56,
          }}
          onClick={handleClearFilters}
        >
          Clear Filters
        </Button>
      </Box>
    </Drawer>
  );
};

export default FiltersDrawer;
